import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStores } from "../../hooks/store/useStores";
import Btn from "../admin/Btn";
import { apiClient } from "../../reactQuery/api";
import { AxiosError } from "axios";
import { queryKeys } from "../../reactQuery/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "react-bootstrap";
import adminApi from "../../function/apiConfig";
import { setCommas } from "../../function/common";

export default function StoreBoard({
  setTotalElement,
  setTotalPage,
  searchState,
  page,
  onLogoDownBtnClick,
  onQRDownBtnClick,
  modal,
  setModal,
}) {
  const { stores, refetch } = useStores({ searchState, page });

  useEffect(() => {
    setTotalElement(stores.totalElements);
    setTotalPage(stores.totalPages);
  }, [setTotalElement, setTotalPage, stores]);

  const acceptStore = (storeId, domain) => {
    adminApi
      .post("/stores/" + storeId + "/examine", {
        acceptStatus: "ACCEPTED",
        domain: domain,
      })
      .then(function () {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const acceptStoreSettlement = (storeId) => {
    adminApi
      .post("/stores/" + storeId + "/store-settlement", {
        approveStatus: "ACCEPTED",
      })
      .then(function () {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const linkPopbill = (storeId) => {
    adminApi
      .post("/stores/" + storeId + "/cash-receipt")
      .then(function (response) {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const checkDomain = (domain) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(domain)) {
      return false;
    } else {
      return true;
    }
  };
  const clickApprove = (storeId) => {
    const _domain = prompt(
      "승인 후 사용할 가맹점 도메인을 입력해주세요 (영어 또는 숫자만) \n ex) 웨이브온 -> waveoncoffee"
    );
    if (!_domain || !checkDomain(_domain)) {
      return null;
    }
    acceptStore(storeId, _domain);
  };

  const clickLinkPopbill = (storeId) => {
    const _confirm = window.confirm("연동 하시겠습니까?");
    if (!_confirm) {
      return null;
    }
    linkPopbill(storeId);
  };

  const clickSettle = (storeId) => {
    const _confirm = window.confirm(
      "정산계좌 등록을 수락 하시겠습니까? \n수락하기전에 팝빌연동을 먼저 진행해주세요!!!"
    );
    if (!_confirm) {
      return null;
    }
    acceptStoreSettlement(storeId);
  };

  function renderStoreAcceptByStatus(acceptStatus, storeId) {
    if (acceptStatus.code == 1) {
      return (
        <td>
          <Button
            onClick={() => {
              clickApprove(storeId);
            }}
            variant="outline-secondary"
          >
            승인하기
          </Button>
        </td>
      );
    } else {
      return <td style={{ minWidth: "5rem" }}>{acceptStatus.description}</td>;
    }
  }

  function renderPopbillByStatus(isLinkedPopbill, storeId) {
    if (isLinkedPopbill) {
      return <td> 연동완료</td>;
    } else {
      return (
        <td>
          <Button
            onClick={() => {
              clickLinkPopbill(storeId);
            }}
            variant="outline-secondary"
          >
            연동하기
          </Button>
        </td>
      );
    }
  }

  function renderSettleByStatus(status, storeId) {
    if (status && status.code == 1) {
      return (
        <td>
          <Button
            onClick={() => {
              clickSettle(storeId);
            }}
            variant="outline-secondary"
          >
            수락 하기
          </Button>
        </td>
      );
    } else if (status) {
      return <td> {status.description}</td>;
    } else {
      return <td> 작성 중</td>;
    }
  }
  function renderRadioByStatus({ status, id, storeName, type }) {
    return (
      <td>
        <Form.Check
          checked={status}
          style={{ cursor: "pointer" }}
          onChange={() => {
            onClickRadio({ id, storeName, type, use: !status });
          }}
        />
      </td>
    );
  }

  const onClickRadio = ({ id, storeName, type, use }) => {
    const confirm = window.confirm(
      `${storeName} 가맹점 ${storeFunctionConvert(type)} ${
        use ? "사용하도록" : "사용하지 않도록"
      } 변경 하시겠습니까?`
    );
    if (confirm) {
      adminApi
        .post("/stores/" + id + "/store-function", {
          type,
          use,
        })
        .then(function (response) {
          alert(
            `${storeName} 가맹점 ${storeFunctionConvert(type)} ${
              use ? "사용하도록" : "사용하지 않도록"
            } 변경 되었습니다.`
          );
          refetch();
        })
        .catch(() => {
          alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
        });
    }
    return;
  };

  const onChangeStoreValueLimit = ({ id, storeName, limit = 0 }) => {
    adminApi
      .post("/stores/" + id + "/store-value-limit", {
        limit,
      })
      .then(function (response) {
        alert(
          `${storeName} 가맹점 변경한도 ${setCommas(
            limit
          )} 원으로 변경 되었습니다.`
        );
        refetch();
      })
      .catch(() => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
      });
  };

  function clickFee(_storeId, _storeName) {
    adminApi.get("/stores/" + _storeId + "/fee").then(function (response) {
      console.log(response);
      const _couponSettleType = response.couponSettleType.description;

      if (response.couponSettleType.code == 3) {
        const contents = response.storeFees
          .map(
            (x) =>
              x.paymentChannel.name +
              ":" +
              Math.round(x.feeRate * 1000000) / 10000 +
              "%"
          )
          .join("\n");
        setModal({
          showModal: true,
          textOfBody: "수수료 방식 :" + _couponSettleType + "\n" + contents,
          textOfHeader: _storeName + " 수수료 정보",
        });
      } else {
        const contents = "수수료 일괄 5.5%";
        setModal({
          showModal: true,
          textOfBody: "수수료 방식 :" + _couponSettleType + "\n" + contents,
          textOfHeader: _storeName + " 수수료 정보",
        });
      }
    });
  }

  const mapedStores = stores.content.map((store) => {
    const {
      id,
      storeName,
      ownerName,
      status,
      acceptStatus,
      logoUrl,
      domain,
      createdAt,
      isLinkedPopbill,
      settleApproveStatus,
      storeValueLimit,
      useMenu,
      usePass,
      useAutoCashReceipt,
      usePrePaid,
      useDefaultMenuAlimTalk,
      useBulkCreate,
      useReservation,
      useDoorSystem,
      useMenuAutoCancel,
      useKids,
    } = store;

    return (
      <tr key={id} className="board-row-except">
        <td
          style={{
            maxWidth: "4rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {id}
        </td>
        {ownerName && storeName && acceptStatus.code !== 4 ? (
          <>
            <td
              style={{
                maxWidth: "4rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Link to={"/admin/store/stores/" + id}>{storeName}</Link>
            </td>
            <td
              style={{
                maxWidth: "4rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {ownerName}
            </td>
          </>
        ) : (
          <td colSpan={2} style={{ fontWeight: 700, color: "#a1a1a1" }}>
            입점 진행 중
          </td>
        )}
        <td>{status.name}</td>
        {/*<td>{acceptStatus.description}</td>*/}
        {renderStoreAcceptByStatus(acceptStatus, id)}
        {/*<td>{settleApproveStatus?.description ||"작성중"}</td>*/}
        {renderSettleByStatus(settleApproveStatus, id)}
        {renderPopbillByStatus(isLinkedPopbill, id)}
        {/* <td>
          <a
            href={`${process.env.REACT_APP_USER_URL}admin/product/products?storeId=${id}`}
            target="_blank"
            rel="noreferrer"
          >
            바로가기
          </a>
          <Link
           to={"/admin/product/products?storeId=" + id}
           className="btn btn-outline-secondary"
          >
           확인하기
          </Link>
        </td> */}

        {/* <td>
         <Btn
           type="EVENT"
           btntext="로고 다운"
           btnStyle=""
           onClick={() => {
             onLogoDownBtnClick(storeName, logoUrl);
           }}
         />
        </td>
        <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
          <a
            href={`${process.env.REACT_APP_USER_URL}user/${domain}`}
            target="_blank"
            rel="noreferrer"
          >
            바로가기
          </a>
        </td> */}
        <td>
          <Button
            variant="outline-secondary"
            onClick={() => clickFee(id, storeName)}
          >
            {" "}
            보기{" "}
          </Button>
        </td>
        {/*<td>*/}
        {/*  <Btn*/}
        {/*    type="EVENT"*/}
        {/*    btntext="QR다운"*/}
        {/*    btnStyle=""*/}
        {/*    onClick={() => {*/}
        {/*      onQRDownBtnClick({ domain });*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</td>*/}
        <td>{createdAt.replace("T", " ").substring(0, 16)}</td>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              const price = window.prompt(
                "변경할 한도액을 입력하세요.",
                storeValueLimit
              );
              if (price && !isNaN(parseInt(price))) {
                onChangeStoreValueLimit({ id, storeName, limit: price });
              } else {
                alert("금액을 올바르게 입력해 주세요.");
              }
            }}
          >
            {setCommas(storeValueLimit) + " 원"}
          </span>
        </td>
        {/*{renderETCByType(acceptStatus.code, id)}*/}
        {renderRadioByStatus({ status: useMenu, id, storeName, type: "MENU" })}
        {renderRadioByStatus({ status: usePass, id, storeName, type: "PASS" })}
        {renderRadioByStatus({
          status: useDefaultMenuAlimTalk,
          id,
          storeName,
          type: "DEFAULT_MENU_ALIM_TALK",
        })}

        {renderRadioByStatus({
          status: useAutoCashReceipt,
          id,
          storeName,
          type: "AUTO_CASH_RECEIPT",
        })}
        {renderRadioByStatus({
          status: usePrePaid,
          id,
          storeName,
          type: "PREPAID",
        })}
        {renderRadioByStatus({
          status: useBulkCreate,
          id,
          storeName,
          type: "BULK_CREATE",
        })}
        {renderRadioByStatus({
          status: useReservation,
          id,
          storeName,
          type: "RESERVATION",
        })}
        {renderRadioByStatus({
          status: useDoorSystem,
          id,
          storeName,
          type: "DOOR_SYSTEM",
        })}
        {renderRadioByStatus({
          status: useMenuAutoCancel,
          id,
          storeName,
          type: "MENU_AUTO_CANCEL",
        })}
        {renderRadioByStatus({
          status: useKids,
          id,
          storeName,
          type: "KIDS",
        })}
        {/* {renderRadioByStatus({
          status: 'status',
          id,
          storeName,
          type: "type",
        })} */}
      </tr>
    );
  });

  return mapedStores;
}

const storeFunctionConvert = (storeFunction) => {
  switch (storeFunction) {
    case "MENU":
      return "주문형 상품 기능";
    case "PASS":
      return "패스방식 기능";
    case "AUTO_CASH_RECEIPT":
      return "현금영수증 자동 자진발급 기능";
    case "PREPAID":
      return "현장발행 기능";
    case "DEFAULT_MENU_ALIM_TALK":
      return "추가 알림톡 발송 기능";
    case "BULK_CREATE":
      return "대량발행 기능";
    case "RESERVATION":
      return "예약 상품 기능";
    case "DOOR_SYSTEM":
      return "출입관제 기능";
    case "MENU_AUTO_CANCEL":
      return "메뉴 주문 자동취소 기능";
    case "KIDS":
      return "키즈카페 솔루션 사용";
    default:
      return "알 수 없는 기능";
  }
};
